<template>
  <v-footer
    app
    padless
    absolute
  >
    <v-card
      class="text-center"
      flat
      tile
      width="100%"
    >
      <v-divider></v-divider>

      <v-card-text>
        <v-icon>mdi-copyright</v-icon>
        Copyright {{ new Date().getFullYear() }} <span class="text--primary">riivr.io</span>
        All Rights Reserved
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data: () => ({
    items: [
      'default',
      'absolute',
      'fixed',
    ],
  }),
};
</script>
